/* globals window webkitSpeechRecognition performance app */

/**
 * This service uses google chrome's webspeech API in the background
 * to create a transcript for the user
 */

var global = this;

(function () {
  'use strict'

  var WebSpeech = (function () {
    var WebSpeech = function () {
      this.webSpeechCapable = true
      if (!window.webkitSpeechRecognition) {
        this.webSpeechCapable = false
        return
      }
      this.reset()

      var WebSpeechApi = webkitSpeechRecognition // standardjs only allows uppercase classes
      this.recognitionApi = new WebSpeechApi()
      this.recognitionApi.continuous = true
      this.recognitionApi.interimResults = false
      this.recognitionApi.onresult = this.onSpeechResult.bind(this)
      this.recognitionApi.onerror = this.onSpeechError.bind(this)
      this.recognitionApi.onend = this.onSpeechEnd.bind(this)
    }
    WebSpeech.prototype.reset = function () {
      this.shouldListen = false
      this.recordingStartTimestamp = null
      this.savedTranscriptions = []
      this.recordingId = null
    }
    WebSpeech.prototype.startRecording = function (recordingStartTimestamp, recordingId) {
      this.recordingStartTimestamp = recordingStartTimestamp
      this.recordingId = recordingId
      this._startWebSpeechListening()
    }
    WebSpeech.prototype._startWebSpeechListening = function () {
      if (!this.webSpeechCapable) {
        return
      }
      this.recognitionApi.start()
      this.shouldListen = true
    }
    WebSpeech.prototype._endWebSpeechListening = function () {
      if (!this.webSpeechCapable) {
        return
      }
      this.recognitionApi.stop()
    }
    WebSpeech.prototype.stopRecording = function () {
      // just to be sure
      try {
        this.shouldListen = false
        // first get the remaining transcription
        this._endWebSpeechListening()
        // then save it to the db
        this.saveTranscription()
      } catch (e) {
        console.error(e)
      }
    }
    WebSpeech.prototype.onSpeechResult = function (event) {
      var resultsLength = event.results.length - 1
      var ArrayLength = event.results[resultsLength].length - 1
      var result = event.results[resultsLength][ArrayLength]
      // https://developers.google.com/web/updates/2016/01/high-res-timestamps
      var endingTimestamp = performance.timing.navigationStart + event.timeStamp
      var transcription = {
        transcript: result.transcript,
        confidence: result.confidence,
        endingTimestamp: endingTimestamp,
        secondsSinceRecordingStart: (endingTimestamp - this.recordingStartTimestamp) / 1000
      }
      this.savedTranscriptions.push(transcription)
    }
    WebSpeech.prototype.onSpeechError = function (error) {
      console.log('webspeech error', error.error)

      // if we get these errors
      if (error.error === 'not-allowed' && error.error === 'service-not-allowed') {
        this.stopRecording()
        return
      }

      // there are certain errors that get thrown where we dont
      // need to restart the listening object
      if (error.error !== 'no-speech') {
        try {
          this.recoverListening()
        } catch (e) {
          console.error(e)
        }
      }
    }
    WebSpeech.prototype.onSpeechEnd = function (event) {
      // console.log('speech end', event)
      this.recoverListening()
    }
    WebSpeech.prototype.recoverListening = function () {
      if (this.shouldListen) {
        this._startWebSpeechListening()
      }
    }
    WebSpeech.prototype.saveTranscription = function () {
      var self = this
      var payload = {
        recordingId: this.recordingId,
        recordingStartTimestamp: this.recordingStartTimestamp,
        transcriptions: this.savedTranscriptions,
        language: window.navigator.language,
        userId: app.user.id
      }

      $.ajax({
        url: '/api/transcriptions/browser-upload',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(payload)
      }).success(function (response) {})
      .error(function (error) {
        console.log(error)
      }).complete(function () {
        self.reset()
      })
    }
    return WebSpeech
  })()
  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = WebSpeech
  else global.WebSpeech = WebSpeech
})()
