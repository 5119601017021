/* globals debug */

(function () {
  'use strict'

  var dbg = debug('zc:workerPromise')

  // This mixin adds promise based communication between the ineriting object and a worker
  //
  // The worker will receive an id with incoming messages and must return the same id with
  // the response

  // management for promises when communicating with worker from main thread
  // intentially shared across all objects with the mixin applied so we always
  // have an incremented id for each request
  var resolves = {}
  var rejects = {}
  var globalMsgId = 0

  var workerPromise = {
    mixin: function (obj) {
      return Object.assign(obj, {

        workerPostMessage: function (message, transferables) {
          var self = this
          var msgId = ++globalMsgId
          message.id = msgId
          dbg(self.name, ' - postMessage: (', msgId, ') ', message)
          if (resolves[msgId]) console.warn('a resolve with the id (', msgId, ') shouldnt exist yet')
          if (rejects[msgId]) console.warn('a reject with the id (', msgId, ') shouldnt exist yet')

          return new Promise(function (resolve, reject) {
            // save callbacks for later
            resolves[msgId] = resolve
            rejects[msgId] = reject

            if (!self.addedEventListener) { // only create a single message event listener
              self.workerPort.addEventListener('message', self.workerOnMessage.bind(self), false)
              self.addedEventListener = true
              self.workerPort.start()
            }

            // dbg(self.name, ' - msgId: ', globalMsgId, ' - ', message)
            self.workerPort.postMessage(message, transferables)
          })
        },

        workerOnMessage: function (e) {
          var id = e.data.id
          if (!id) {
            if (e.data.result) console.error(this.name, ' Missing id for a message that appears should have one: ', e.data)
            return // ignore messages without ids
          }
          var err = e.data.err
          var result = e.data.result
          dbg(this.name, ' - onMessage: (', e.data.id, ') ', result)
          if (!resolves[e.data.id]) console.warn('workerPromise: a resolve with the id (', e.data.id, ') should exist')
          if (!rejects[e.data.id]) console.warn('workerPromise: a reject with the id (', e.data.id, ') should exist')

          if (err) {
            // error condition
            var reject = rejects[id]
            if (reject) {
              dbg(this.name, ' - onMessage: claiming reject (', e.data.id, ') ', err)
              reject(err)
            }
          } else {
            var resolve = resolves[id]
            if (resolve) {
              dbg(this.name, ' - onMessage: claiming resolve (', e.data.id, ') ', result)
              resolve(result)
            }
          }

          // purge used callbacks
          resolves[id] = undefined
          rejects[id] = undefined
        }
      })
    }
  }
  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = workerPromise
  else global.workerPromise = workerPromise
})()
