/* globals debug MemoryStore workerPromise events */

(function () {
  'use strict'

  var dbg = debug('zc:memoryStoreWorkerProxy')

  var MemoryStoreWorkerProxy = (function () {
    var MemoryStoreWorkerProxy = function (config) {
      dbg('Initialized with config: ', config)
      MemoryStore.call(this, config)

      if (config.workerPort) {
        this.workerPort = config.workerPort
      } else {
        this.messageChannel = new MessageChannel()
        this.workerPort = this.messageChannel.port1
      }

      this.initPortListeners()
    }

    // must be first after constructor
    MemoryStoreWorkerProxy.prototype = Object.create(MemoryStore.prototype)

    MemoryStoreWorkerProxy.prototype.getMessagePort = function () {
      return this.messageChannel.port2
    }

    MemoryStoreWorkerProxy.prototype.initPortListeners = function () {
      var self = this
      var port = this.workerPort
      port.addEventListener('message', function (e) {
        switch (e.data.command) {
          case 'change:bytesRecorded':
            self.trigger('change:bytesRecorded', e.data.bytesRecorded)
            break
          case 'localbackup:failedWrite':
            self.trigger('localbackup:failedWrite')
            break
        }
      }, false)

      port.start()
    }

    MemoryStoreWorkerProxy.prototype.export = function () {
      console.time('memoryStoreWorkerProxyExport')
      return this.workerPostMessage({
        command: 'export'
      }).then(function (chunks) {
        console.timeEnd('memoryStoreWorkerProxyExport')
        return chunks
      })
    }

    MemoryStoreWorkerProxy.prototype.getBytesSaved = function () {
      return this.workerPostMessage({
        command: 'getBytesSaved'
      }).then(function (chunk) {
        return chunk
      })
    }

    MemoryStoreWorkerProxy.prototype.clear = function (unmerged) {
      this.workerPostMessage({command: 'clear'})
    }

    workerPromise.mixin(MemoryStoreWorkerProxy.prototype)
    events.mixin(MemoryStoreWorkerProxy.prototype)

    return MemoryStoreWorkerProxy
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = MemoryStoreWorkerProxy
  else window.MemoryStoreWorkerProxy = MemoryStoreWorkerProxy
})()
