/* globals PersistentStore workerPromise debug */

// Top level interface for accessing persistent indexeddb stores
// Can be configured to access using the main thread or via a web worker
// using one of the child modules
var global = this;

(function () {
  'use strict'

  var dbg = debug('zc:persistentStoreWorkerProxy')

  var PersistentStoreWorkerProxy = (function () {
    var PersistentStoreWorkerProxy = function (config) {
      dbg('Initialized with config: ', config)
      PersistentStore.call(this, config)

      if (config.workerPort) {
        this.workerPort = config.workerPort
      } else {
        this.messageChannel = new MessageChannel()
        this.workerPort = this.messageChannel.port1
      }

      this.initPortListeners()
    }

    // must be first after constructor
    PersistentStoreWorkerProxy.prototype = Object.create(PersistentStore.prototype)

    PersistentStoreWorkerProxy.prototype.getMessagePort = function () {
      return this.messageChannel.port2
    }

    PersistentStoreWorkerProxy.prototype.initPortListeners = function () {
      var self = this
      var port = this.workerPort
      port.addEventListener('message', function (e) {
        switch (e.data.command) {
          case 'saveError':
            self.handleSaveError(e.data.err)
            break
          case 'change:bytesSaved':
            self.trigger('change:bytesSaved', e.data.bytesSaved, e.data.chunksIn, e.data.chunksOut)
            break
        }
      }, false)

      port.start()
    }

    PersistentStoreWorkerProxy.prototype.getDbMeta = function () {
      return this.workerPostMessage({command: 'getDbMeta'})
    }

    PersistentStoreWorkerProxy.prototype.add = function (item) {
      return this.workerPostMessage({
        command: 'add',
        item: item
      })
    }

    PersistentStoreWorkerProxy.prototype.addAudio = function (audioChunk) {
      return this.workerPostMessage({
        command: 'addAudio',
        audioChunk: audioChunk
      })
    }

    PersistentStoreWorkerProxy.prototype.export = function () {
      return this.workerPostMessage({
        command: 'export'
      })
    }

    PersistentStoreWorkerProxy.prototype.mergedExport = function () {
      return this.workerPostMessage({
        command: 'mergedExport'
      })
    }

    PersistentStoreWorkerProxy.prototype.clear = function () {
      return this.workerPostMessage({command: 'clear'})
    }

    PersistentStoreWorkerProxy.prototype.dbClose = function () {
      return this.workerPostMessage({command: 'dbClose'})
    }

    workerPromise.mixin(PersistentStoreWorkerProxy.prototype)

    return PersistentStoreWorkerProxy
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = PersistentStoreWorkerProxy
  else global.PersistentStoreWorkerProxy = PersistentStoreWorkerProxy
})()
