/* globals _ */
(function () {
  'use strict'

  var Player = window.Player = function (actx) {
    this.actx = actx || new AudioContext()
    this.samples = []
  }

  Player.prototype.loadSamples = function (samples) {
    var self = this
    var bufferRequests = []
    samples.forEach(function (sample) {
      bufferRequests.push(self.loadSampleBuffer(sample))
    })

    return Promise.all(bufferRequests).then(function (samples) {
      self.samples = self.samples.concat(samples)
    })
  }

  Player.prototype.loadSampleBuffer = function (sample) {
    var self = this
    return new Promise(function (resolve, reject) {
      var request = new XMLHttpRequest()
      request.open('GET', sample.url, true)
      request.responseType = 'arraybuffer'

      // Decode asynchronously
      request.onload = function () {
        if (this.status === 200) {
          self.actx.decodeAudioData(request.response, function (buffer) {
            sample.buffer = buffer
            resolve(sample)
          }, reject)
        } else {
          reject(this.statusText)
        }
      }
      request.send()
    })
  }

  Player.prototype.play = function (name, gain) {
    var sound = _.findWhere(this.samples, {name: name})
    if (sound) {
      return this.playBuffer(sound.buffer, gain)
    }
  }

  Player.prototype.stop = function () {

  }

  Player.prototype.playBuffer = function (buffer, gain) {
    var self = this
    var source = self.actx.createBufferSource()
    var gainNode = self.actx.createGain()
    gainNode.gain.value = gain || 0.5
    source.buffer = buffer
    source.loop = true
    source.connect(gainNode)
    // gainNode.connect(self.actx.destination);
    source.start(0)

    return {source: source, gain: gainNode}
  }
})()
